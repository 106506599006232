html {
  height: 100%;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

body {
  height: 100%;
  margin: 0;
  line-height: inherit;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F2F5FD;
}

.forgot-password-container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}

.forgot-password {
  margin-right: 20px;

  &.btn-submit {
    background: #00f;
    color: #fff;
    width: 125px;
  }

  &.btn-cancel {
    width: 100px;
    color: #fff;
    background: grey;
  }

}

.container {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.header {
  flex-direction: column;
  flex: 0.15;
  background: white;
}

.view {
  background: #F2F5FD;
  flex: 0.85;
}

a {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

p {
  margin: 0px;
}

h1 {
  color: #333333;
  font-size: 28px;
  font-weight: 400;;
  margin: 0px;
}

h2 {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}

.flex {
  display: flex !important;

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.middle {
    align-items: center;
  }

  &.left {
    justify-content: flex-start;
  }
}

.add-student {
  .modal-close {
    text-align: right;
    top: 0;
    margin: 15px;
    font-size: 20px;
    color: #1b91c7;
    cursor: pointer;
  }

  .modal-dialog {
    width: 320px;
  }

  .modal-title {
    padding: 15px 15px 0 15px;
  }

  .modal-content {
    border-radius: 8px;
  }

  .modal-body {
    padding: 0px 15px;
  }

  .modal-footer {
    border-top: none;
    justify-content: flex-start;
    padding: 15px;

    button {
      background: #85BA2F;
      border: none;
      margin: 0;
    }
  }

  i {
    font-size: 24px;
  }

  h2 {
    font-size: 24px;
  }
}

.ag-grid-center-cell {
  display: flex;
  align-items: center;
}

.sign-in {
  height: 100%;

  a {
    text-align: left;
    margin-top: 10px;
    padding-left: 10px
  }
}
.ag-root-wrapper {
  border: none !important;
}

.ag-header {
  border: 1px solid rgba(0,0,0,0.05) !important;
  height: 50px !important;
}

.ag-header-row {
  height: 100% !important;
}

.ellipsis-container {
  float: right;
  margin-right: 15px;
  padding: 0px 15px;
  cursor: pointer;
}

.ellipsis-popover .popover .arrow {
  display: none;
}

.ellipsis-popover .popover {
  top: -10px !important;
  left: 10px !important;
  border: 1px solid #E4E4E7;
  border-radius: 8px;
  padding: 15px;
}

.row-popover {
  color: #1b91c7;
}
